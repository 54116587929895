.shadow_bottom {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
}
.shadow_bottom:hover {
    box-shadow: 0px 9px 20px rgba(75, 72, 72, 0.3);
}
.sub_scrollbar::-webkit-scrollbar {
    width: 5px;
}
.sub_scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}
.sub_scrollbar::-webkit-scrollbar-thumb {
    background: #4d4d4d;
    border-radius: 10px;
}
.sub_scrollbar::-webkit-scrollbar-thumb:hover {
    background: #999999;
}
a {
    color: #022648;
    text-decoration: none;
    background-color: transparent;
    transition: .3s;
}
a:hover {
    color: var(--secondary) !important;
    text-decoration: none;
}
a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
    outline: 0;
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
button {
    border-radius: 0;
    outline: none !important;
    border: none;
    background: transparent;
}
button:hover {
    cursor: pointer;
}
button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

/* Buttons */
.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.3rem 0.7rem;
    font-size: 0.9em;
    line-height: 1.5;
    border-radius: 0.35rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn:hover {
    text-decoration: none;
}
.btn:focus,
.btn.focus {
    outline: 0;
    box-shadow: none;
}
.btn.disabled,
.btn:disabled {
    opacity: 0.65;
    cursor: not-allowed;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn_info {
    color: #fff;
    background-color: var(--info);
    border-color: var(--info);
}
.btn_info:hover {
    color: #fff;
    background-color: var(--infohover);
    border-color: var(--infohover);
}
.btn_primary {
    color: #fff;
    background-color: #022648;
    border-color: #022648;
}
.btn_primary:hover {
    color: #fff;
    background-color: #eac435;
    border-color: #eac435;
}
.btn_secondary {
    color: #fff;
    background-color: #eac435;
    border-color: #eac435;
}
.btn_secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
}
.btn_danger {
    color: #fff;
    background-color: var(--danger);
    border-color: var(--danger);
}

.btn_danger:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
}

.btn_focus {
    color: #fff;
    background-color: var(--focus);
    border-color: var(--focus);
}

.btn_focus:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
}

.btn_dark {
    color: #fff;
    background-color: var(--dark);
    border-color: var(--dark);
}

.btn_dark:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
}
.opacity6 {
    opacity: 0.6 !important;
}
section {
    padding: 0 50px;
    /* min-height: 60vh; */
}
@media screen and (max-width: 500px) {
    section {
        padding: 0 20px;
    }
}
/* Inner Content */
.app_container {
    margin: 0;
    width: 100vw !important;
}
.app_container_inner {
    padding-top: 80px;
}

/* Card */
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    /* border: 1px solid #e6e6e6; */
    border-radius: 10px;
    padding: 0.5em 0.8em;
    /* box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03); */
    /* border-width: 0; */
    transition: all 0.2s;
}
.activecard{
    transform: translateY(-4px);
    color: var(--secondary) !important
}
.cardhover:hover{
    transition: .3s;
    cursor: pointer;
    transform: translateY(-4px);
    color: var(--secondary) !important
}
.card > hr {
    margin-right: 0;
    margin-left: 0;
}
.card_border {
    box-shadow: 0 0 0 transparent;
    border-width: 1px;
}
@media (max-width: 800px) {
    body .card.mb-3 {
        margin-bottom: 15px !important;
    }
}
.fadeIn {
    display: block;
    visibility: visible;
    transition: 1s ease-in-out;
    transition-delay: 0s;
}
.fadeOut {
    display: none;
    visibility: hidden;
    transition: 1s ease-in-out;
}
.fadeInButton {
    display: block;
}
.fadeOutButton {
    display: none;
}
.sectionTitle {
    font-size: 28px;
    position: relative;
    color: var(--primary);
    font-family: Poppins-Bold;
}
@media screen and (max-width:800px) {
    .sectionTitle{
        font-size: 25px;
    }
    
}
.square {
    height: 100px;
    width: 100px;
    border-radius: 20px;
    background-color: #e7ba18;
    position: absolute;
    left: -40px;
    top: -10px;
    z-index: -1;
    transform: rotate(-30deg);
}
.square2 {
    height: 100px;
    width: 100px;
    border-radius: 20px;
    background-color: var(--success);
    position: absolute;
    right: 30px;
    top: -10px;
    z-index: -1;
    transform: rotate(30deg);
}
.circle {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: #16aaff;
    position: absolute;
    right: -30px;
    bottom: -30px;
    z-index: -1;
    transform: rotate(30deg);
}
.circle2 {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: var(--danger);
    position: absolute;
    left: 20px;
    top: 200px;
    z-index: -1;
    transform: rotate(30deg);
}

.avatar_edit input {
    display: none;
}
.avatar_upload .avatar_edit input + label {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.avatar_upload .avatar_preview {
    width: 192px;
    height: 192px;
    position: relative;
    border-radius: 20px;
    border: 6px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.avatar_upload_logo .avatar_preview {
    border-radius: 10px;
}
.avatar_upload .avatar_preview > div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.avatar_preview img {
    border-radius: 5px;
    max-width: 95%;
    max-height: 95%;
}
.avatar_upload:hover .imgpreviewtxt {
    color: var(--primaryColor);
}
.avatar_upload:hover .imgpreviewtxt {
    color: var(--secondaryColor);
}
.imgpreviewtxt,
.imgpreviewpostertxt {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    transition: 0.3s;
}
.avatar_upload:hover .imgpreviewtxt {
    color: var(--secondary);
}

.pricing_btn{
    width: 100%;
    height: 40px;
    border-radius: 11px;
}