/* Forms */
.form_control {
    display: block;
    width: 100%;
    height: 25px;
    font-size: 1rem;
    color: var(--cardtitle);
    background: no-repeat center bottom, center calc(100% - 1px);
    background-size: 0 100%, 100% 100%;
    background-clip: padding-box;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px;
    transition: background 0s ease-out;
    border: 0;
    padding: 0 10px;
    font-weight: 600;
}
@media screen and (prefers-reduced-motion: reduce) {
    .form_control {
        transition: none;
    }
}
.form_control::-ms-expand {
    background-color: transparent;
    border: 0;
}
.form_control:focus {
    outline: 0;
}
.form_control::placeholder {
    color: #eac435;
    opacity: 1;
}
.form_control:disabled,
.form_control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
    cursor: not-allowed;
}
textarea.form_control {
    height: auto;
}
.form_control:focus {
    background-size: 100% 100%, 100% 100%;
    transition-duration: 0.3s;
    box-shadow: none;
}
.form_control::-moz-placeholder {
    color: #aaaaaa;
    font-weight: 400;
    font-size: 14px;
}
.form_control:-ms-input-placeholder {
    color: #aaaaaa;
    font-weight: 400;
    font-size: 14px;
}
.form_control::-webkit-input-placeholder {
    color: #aaaaaa;
    font-weight: 400;
    font-size: 14px;
}
.form_control {
    background-image: linear-gradient(to top, #eac435 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.form_control_border_none {
    background-image: none;
}
.form_control.disabled,
.form_control:disabled,
.form_control[disabled] {
    background-image: linear-gradient(to right, #d2d2d2 0%, #d2d2d2 30%, transparent 30%, transparent 100%);
    background-repeat: repeat-x;
    background-size: 3px 1px;
    cursor: not-allowed;
}
.input_label_name {
    font-size: 14px;
    text-transform: capitalize;
    /* color: #000; */
    color: rgb(172, 172, 172);
    font-weight: 400;
    text-align: start !important;
}
.input_label_name-bolder {
    font-weight: 500;
}
.input_icon {
    position: absolute;
    bottom: 5px;
    left: 0;
    color: #d9d9d9;
}
/* Radio Buttons */
.radiobtn_label {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0;
}
.radiobtn_label input {
    position: absolute;
    left: -9999px;
    display: none;
}
.radiobtn_label input:checked + span {
    background-color: #f0f5f5;
    color: var(--focus);
    font-weight: 500;
}
.radiobtn_label input:checked + span:before {
    box-shadow: inset 0 0 0 0.3em #eac435;
}
.radiobtn_labelhovernone span:hover {
    background-color: transparent !important;
}
.radiobtn_labelhovernone:hover input:checked + span:before {
    box-shadow: inset 0 0 0 0.3em #eac435;
}
.radiobtn_labelhovernone input:checked + span {
    background-color: transparent !important;
}
.radiobtn_label span {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em;
    transition: 0.25s ease;
    font-weight: normal;
    color: var(--focus);
}
.radiobtn_label span:hover {
    background-color: #e6e6e6;
}
.radiobtn_labelhovernone span:hover {
    background-color: transparent !important;
}
.radiobtn_label span:before {
    display: flex;
    flex-shrink: 0;
    content: '';
    background-color: #fff;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin-right: 0.375em;
    transition: 0.25s ease;
    box-shadow: inset 0 0 0 0.125em var(--focus);
}
.radiobtn_label.text-grey span:before {
    box-shadow: inset 0 0 0 0.125em grey;
}
.radiobtn_label input.disabled {
    cursor: not-allowed;
}
.radiobtn_label_translated span:before {
    display: flex;
    flex-shrink: 0;
    content: '';
    background-color: #fff;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin-left: 0.375em;
    margin-right: 0;
    transition: 0.25s ease;
    box-shadow: inset 0 0 0 0.125em var(--focus);
}
.radiobtn_label_translated input.disabled {
    cursor: not-allowed;
}

/* Checkbox */
.checkbox {
    --border: #022648;
    --border-hover: #959ed0;
    --tick: #fff;
    position: relative;
}
.checkbox_label {
    color: var(--primary);
    cursor: pointer;
    transition: 0.3s;
}
.checkbox:hover .checkbox_label {
    color: var(--border-hover);
}
.checkbox input,
.checkbox svg {
    width: 16px;
    height: 16px;
    display: block;
}
.checkbox_sub input {
    width: 15px;
    height: 15px;
}
.checkbox input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
    background: white;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 4px;
    transition: box-shadow 0.3s;
    box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
}
.checkbox input:disabled {
    cursor: not-allowed;
}
.checkbox input:hover {
    --s: 2px;
    --b: var(--border-hover);
}
.checkbox input:checked {
    --b: var(--secondary);
}
.checkbox svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--stroke, var(--secondary));
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 21px;
    transform: scale(var(--scale, 1)) translateZ(0);
}
.checkbox_sub svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--stroke, var(--secondary));
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 21px;
    transform: scale(var(--scale, 1)) translateZ(0);
}
.checkboxtranslated svg {
    right: 0;
}
.checkbox.path input:checked {
    --s: 2px;
    transition-delay: 0.4s;
}
.checkbox.path input:checked + svg {
    --a: 16.1 86.12;
    --o: 102.22;
}
.checkbox.path svg {
    stroke-dasharray: var(--a, 86.12);
    stroke-dashoffset: var(--o, 86.12);
    transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
}
.checkbox p {
    font-size: 15px !important;
    font-weight: 500 !important;
    text-transform: capitalize;
}

.rank {
    font-size: 30px !important;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
}
.star .staroutline {
    display: flex;
    opacity: 1;
    visibility: visible;
    margin-right: 0px;
    color: #5e6b92;
}
.star .starfilled {
    display: none;
    opacity: 0;
    margin-right: 0px;
    visibility: hidden;
}

.rank:hover > .star .staroutline {
    display: none;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
}
.rank:hover > .star .starfilled {
    display: flex;
    opacity: 1;
    visibility: visible;
    cursor: pointer;
}
.rank > .star:hover ~ .star .starfilled {
    display: none;
    opacity: 0;
    visibility: hidden;
}
.rank > .star:hover ~ .star .staroutline {
    display: flex;
    opacity: 1;
    visibility: visible;
}
.rank0 {
    display: flex;
    position: absolute;
    /* right: 0; */
    /* top: 0; */
    font-family: Carbona-Black, ZaridSlab-Regular !important;
    font-weight: 900;
    font-size: 18px;
    line-height: 1.25rem;
    color: #5e6b92;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rank1 {
    display: none;
    position: absolute;
    /* right: 0; */
}
.rank2 {
    display: none;
    position: absolute;
    /* right: 0; */
}
.rank3 {
    display: none;
    position: absolute;
    /* right: 0; */
}
.rank4 {
    display: none;
    position: absolute;
    /* right: 0; */
}
.rank5 {
    display: none;
    position: absolute;
    /* right: 0; */
}

.rank .star:nth-child(1):hover .rank1 {
    display: flex;
    opacity: 1;
    visibility: visible;
    font-family: Carbona-Black, ZaridSlab-Regular !important;
    font-weight: 900;
    font-size: 18px;
    line-height: 1.25rem;
    color: #5e6b92;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rank .star:nth-child(n):hover .rank0 {
    display: flex !important;
    opacity: 1 !important;
    visibility: visible !important;
    font-weight: 600 !important;
    font-size: 18px;
    line-height: 1.25rem !important;
    color: #5e6b92;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rank:hover .rank0 {
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important;
}

.star0 {
    display: flex !important;
    opacity: 1 !important;
    visibility: visible !important;
    margin-right: 0px;
}
.star1 {
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important;
    margin-right: 0px;
}

.rank .star:nth-child(2):hover .rank2 {
    display: flex;
    opacity: 1;
    visibility: visible;
    font-family: Carbona-Black, ZaridSlab-Regular !important;
    font-weight: 900;
    font-size: 18px;
    line-height: 1.25rem;
    color: #5e6b92;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rank .star:nth-child(3):hover .rank3 {
    display: flex;
    opacity: 1;
    visibility: visible;
    font-family: Carbona-Black, ZaridSlab-Regular !important;
    font-weight: 900;
    font-size: 18px;
    line-height: 1.25rem;
    color: #5e6b92;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rank .star:nth-child(4):hover .rank4 {
    display: flex;
    opacity: 1;
    visibility: visible;
    font-family: Carbona-Black, ZaridSlab-Regular !important;
    font-weight: 900;
    font-size: 18px;
    line-height: 1.25rem;
    color: #5e6b92;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rank .star:nth-child(5):hover .rank5 {
    display: flex;
    opacity: 1;
    visibility: visible;
    font-family: Carbona-Black, ZaridSlab-Regular !important;
    font-weight: 900;
    font-size: 18px;
    line-height: 1.25rem;
    color: #5e6b92;
    display: flex;
    justify-content: center;
    align-items: center;
}

.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 22px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #616161;
    background-color: transparent;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 3px;
    bottom: 2.5px;
    background-color: #616161;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #0f6cbd;
    border: 0px !important;
}

input:focus + .slider {
    box-shadow: 0 0 1px rgba(0, 145, 64, 1);
}

input:checked + .slider:before {
    background-color: white;
    bottom: 3px;

    -webkit-transform: translateX(21px);
    -ms-transform: translateX(21px);
    transform: translateX(21px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 1000px;
}

.slider.round:before {
    border-radius: 50%;
}
